import React, { useEffect } from "react"
import Layer from "../components/Layer"
import da from "../js/dynamic_adapt"
import "../css/style.css"
import Seo from "../components/Seo"


export default function Pricing() {
	useEffect(() => {
		da.init()
		setHovers()
	}, [])
	const setHovers = () => {
		const hoversGrid = document.querySelectorAll('[data-grid-hover]')
		// ПРоверяем, есть ли элемент на странице
		if (hoversGrid.length) {
			// ПРобегаемся по всем элементам
			hoversGrid.forEach(grid => {
				// Делигируем событие НАВЕДЕНИЯ мыши
				grid.addEventListener('mouseover', (e) => {
					// Находин элемент, на который навелись
					const target = e.target
					// Проверяем, есть ли у наведенного элемента data-column и (!) ЗАПОЛНЕН (!) ли он 
					// Также рповеряем на отсутсвие класса _active, чтобы лишний раз не запускать код
					if (target.dataset.column && !target.classList.contains('_active')) {
						// Принимаем значение номера колонки в постоянную
						const numberColumn = target.dataset.column
						// Находим все элементы в грид сетке с data-column с необходимым нам значением 
						const elements = grid.querySelectorAll(`[data-column="${numberColumn}"]`)
						// Пробегаемся по всем элементам
						elements.forEach((element, index) => {
							// Через css что-то не получилось подобраться к такому кобьекту, поэтому добавил класс
							// Добавление класса к первому ...
							if (index === 0) element.classList.add('_first')
							// ... и последнему элементу
							if (index === elements.length - 1) element.classList.add('_last')
							// Добавление класса _active ВСЕМ ячейкам
							element.classList.add('_active')
						})
					}
				})

				// Делигируем событие "убирания" мыши
				// Далее все тоже самое, только удаляем класс
				grid.addEventListener('mouseout', (e) => {
					const target = e.target
					if (target.dataset.column && target.classList.contains('_active')) {
						const numberColumn = target.dataset.column
						const elements = grid.querySelectorAll(`[data-column="${numberColumn}"]`)
						elements.forEach((element, index) => {
							if (index === 0) element.classList.remove('_first')
							if (index === elements.length - 1) element.classList.remove('_last')
							element.classList.remove('_active')
						})
					}
				})
			})
		}
	}
	return (
		<>
			<Seo title="Pricing"/>
			<Layer>
				<main className="pricing-page">
					<div className="pricing-page__container">
						<div className="pricing-page__title title-pricing">
							<h2 className="title-pricing__title">Pricing</h2>
						</div>
						<div data-grid-hover className="pricing-page__body pricing-grid">
							<div className="pricing-grid__title pricing-grid__title_empty"></div>
							<div data-column="1" className="pricing-grid__title">SOLmate account</div>
							<div data-column="2" className="pricing-grid__title">SOLmate account
								<br />+Virtual card</div>
							<div data-column="3" className="pricing-grid__title">SOLmate account
								<br />+Debit card</div>
							<div className="pricing-grid__subtitle pricing-grid__subtitle_empty"></div>
							<div data-column="1" className="pricing-grid__subtitle">Free</div>
							<div data-column="2" className="pricing-grid__subtitle">R15 p/w</div>
							<div data-column="3" className="pricing-grid__subtitle">R25 p/w</div>
							<div className="pricing-grid__image pricing-grid__image_empty"></div>
							<div data-column="1" className="pricing-grid__image pricing-grid__image_free">
								<picture>
									<source srcSet="/pricing-page/pricing-mobile-image-01.webp" type="image/webp" />
									<img data-column="1" src="/pricing-page/pricing-mobile-image-01.png?_v=1638553701131" alt="" />
								</picture>
							</div>
							<div data-column="2" className="pricing-grid__image pricing-grid__image_virtual">
								<picture>
									<source srcSet="/pricing-page/pricing-mobile-image-02.webp" type="image/webp" />
									<img data-column="2" src="/pricing-page/pricing-mobile-image-02.png?_v=1638553701131" alt="" />
								</picture>
							</div>
							<div data-column="3" className="pricing-grid__image  pricing-grid__image_debit">
								<picture>
									<source srcSet="/pricing-page/pricing-card-image-03.webp" type="image/webp" />
									<img data-column="3" src="/pricing-page/pricing-card-image-03.png?_v=1638553701131" alt="" />
								</picture>
							</div>
							<div className="pricing-grid__button pricing-grid__button_empty"></div>
							<div data-column="1" className="pricing-grid__button"><a data-column="1" href="https://solcard.onelink.me/xMJ9/53a121ee" className="button-body">Download</a>
							</div>
							<div data-column="2" className="pricing-grid__button"><a data-column="2" href="https://solcard.onelink.me/xMJ9/53a121ee" className="button-body">Download</a>
							</div>
							<div data-column="3" className="pricing-grid__button"><a data-column="3" href="https://solcard.onelink.me/xMJ9/53a121ee" className="button-body">Apply now</a>
							</div>
							<div className="pricing-grid__category">Card monthly fee</div>
							<div data-column="1" className="pricing-grid__price">0</div>
							<div data-column="2" className="pricing-grid__price">R15.00</div>
							<div data-column="3" className="pricing-grid__price">R25.0</div>
							<div className="pricing-grid__category">Online card payments</div>
							<div data-column="1" className="pricing-grid__price">n/a</div>
							<div data-column="2" className="pricing-grid__price">R1.5</div>
							<div data-column="3" className="pricing-grid__price">R1.5</div>
							<div className="pricing-grid__category">ATM withdrawal</div>
							<div data-column="1" className="pricing-grid__price">n/a</div>
							<div data-column="2" className="pricing-grid__price">n/a</div>
							<div data-column="3" className="pricing-grid__price">R10+1%</div>
							<div className="pricing-grid__category">Funeral cover</div>
							<div data-column="1" className="pricing-grid__price">n/a</div>
							<div data-column="2" className="pricing-grid__price">n/a</div>
							<div data-column="3" className="pricing-grid__price">Coming in Q1 2022</div>
							<div className="pricing-grid__category">Account fee</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">ATM Topup</div>
							<div data-column="1" className="pricing-grid__price">10R per 1000R</div>
							<div data-column="2" className="pricing-grid__price">10R per 1000R</div>
							<div data-column="3" className="pricing-grid__price">10R per 1000R</div>
							<div className="pricing-grid__category">EFT in</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">Airtime</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">Electricity</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">Watter below meter</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">Betting voucher</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">Lottery voucher</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">DSTV</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">Minimum balance</div>
							<div data-column="1" className="pricing-grid__price">no</div>
							<div data-column="2" className="pricing-grid__price">no</div>
							<div data-column="3" className="pricing-grid__price">no</div>
							<div className="pricing-grid__category">Transfer user by phone</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">Special deals catalogues</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">3D secure SMS</div>
							<div data-column="1" className="pricing-grid__price">free</div>
							<div data-column="2" className="pricing-grid__price">free</div>
							<div data-column="3" className="pricing-grid__price">free</div>
							<div className="pricing-grid__category">Swipe fee</div>
							<div data-column="1" className="pricing-grid__price">n/a</div>
							<div data-column="2" className="pricing-grid__price">R1.5</div>
							<div data-column="3" className="pricing-grid__price">R1.5</div>
							<div className="pricing-grid__category">Card load fee</div>
							<div data-column="1" className="pricing-grid__price">n/a</div>
							<div data-column="2" className="pricing-grid__price">R10</div>
							<div data-column="3" className="pricing-grid__price">R10</div>
							<div className="pricing-grid__category">Card unload fee</div>
							<div data-column="1" className="pricing-grid__price">n/a</div>
							<div data-column="2" className="pricing-grid__price">R4</div>
							<div data-column="3" className="pricing-grid__price">R4</div>
							<div className="pricing-grid__category">EFT out</div>
							<div data-column="1" className="pricing-grid__price">R4</div>
							<div data-column="2" className="pricing-grid__price">R4</div>
							<div data-column="3" className="pricing-grid__price">R4</div>
							<div className="pricing-grid__category">RTC (instant EFT)</div>
							<div data-column="1" className="pricing-grid__price">R40</div>
							<div data-column="2" className="pricing-grid__price">R40</div>
							<div data-column="3" className="pricing-grid__price">R40</div>
							<div className="pricing-grid__category">Ewallet voucher</div>
							<div data-column="1" className="pricing-grid__price">R20</div>
							<div data-column="2" className="pricing-grid__price">R20</div>
							<div data-column="3" className="pricing-grid__price">R20</div>
							<div className="pricing-grid__category">Retail withdrawal</div>
							<div data-column="1" className="pricing-grid__price">coming soon</div>
							<div data-column="2" className="pricing-grid__price">coming soon</div>
							<div data-column="3" className="pricing-grid__price">coming soon</div>
							<div className="pricing-grid__category">Retail topup</div>
							<div data-column="1" className="pricing-grid__price">coming soon</div>
							<div data-column="2" className="pricing-grid__price">coming soon</div>
							<div data-column="3" className="pricing-grid__price">coming soon</div>
							<div className="pricing-grid__category">Activation fee</div>
							<div data-column="1" className="pricing-grid__price pricing-grid__price_bottom">free</div>
							<div data-column="2" className="pricing-grid__price pricing-grid__price_bottom">R15</div>
							<div data-column="3" className="pricing-grid__price pricing-grid__price_bottom">R120</div>
						</div>
					</div>
				</main>
			</Layer>
		</>
	)
}